import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Icon from 'components/ui-components-v2/Icon';
import TextField from 'components/ui-components-v2/TextField';

interface Props {
    onMutation?: (val: string) => void;
    value: string | number;
    step: number;
    min: string;
    max: string;
    startAdornment: string;
    startIcon: string;
    endAdornment: string;
    endIcon: string;
    readOnly: boolean;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
}
/**
 * Number field
 * Display a number in the inout
 */
const NumberInput = ({
    onMutation = () => {
        return null;
    },
    value,
    step = 1,
    min,
    max,
    startAdornment,
    startIcon,
    endAdornment,
    endIcon,
    placeholder,
    className,
    readOnly = false,
    disabled
}: Props) => {
    const [localValue, setLocalValue] = useState<string | number>(value || value === '' ? value : '0');

    useEffect(() => {
        if (value === undefined || value === null) return;
        setLocalValue(value);
    }, [value]);

    /**
     * Cap the value to the min and max
     * @param val value to cap
     * @returns capped value
     */
    const capValue = (val): string => {
        if (!min && !max) return val;

        let newVal = typeof val === 'string' ? parseFloat(val) : val;

        if (isNaN(newVal)) newVal = min;

        if (min !== undefined && newVal < parseFloat(min)) {
            newVal = parseFloat(min);
        }

        if (max !== undefined && newVal > parseFloat(max)) {
            newVal = parseFloat(max);
        }

        return newVal.toString();
    };

    /**
     * OnChange
     * Change the value of the switch also checks if the value needs to be capped if so dont send it to the mutation
     */
    const onChange = (event) => {
        const val = event.target.value;
        if (min || max) {
            //Only cap values if min or max is set
            const cappedVal = capValue(val);

            if (val === cappedVal) {
                onMutation(val);
            }
        } else {
            onMutation(val);
        }
        setLocalValue(val);
    };

    /**
     * Checks if the value needs to be capped and if so caps it.
     */
    const onBlur = () => {
        const cappedVal = capValue(localValue);
        if (cappedVal !== localValue) {
            onMutation(cappedVal);
            setLocalValue(cappedVal);
        }
    };

    let adornment;
    if (startAdornment) {
        adornment = <InputAdornment position="start">{startAdornment}</InputAdornment>;
    } else if (startIcon) {
        adornment = (
            <InputAdornment position="start">
                <Icon style={{ color: '#707070' }}>{startIcon}</Icon>
            </InputAdornment>
        );
    }
    let adornmentEnd;
    if (endAdornment) {
        adornmentEnd = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    } else if (endIcon) {
        adornmentEnd = (
            <InputAdornment position="end">
                <Icon style={{ color: '#707070' }}>{endIcon}</Icon>
            </InputAdornment>
        );
    }

    // Read only, show value
    if (readOnly) {
        return <div>{localValue}</div>;
    }

    return (
        <TextField
            className={className}
            disabled={disabled}
            margin="dense"
            variant="outlined"
            onChange={onChange}
            type="number"
            size="medium"
            value={localValue}
            style={{ marginTop: 0, width: '100%' }}
            onBlur={onBlur}
            onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
            placeholder={placeholder}
            slotProps={{
                htmlInput: { step, min, max },
                input: {
                    startAdornment: adornment,
                    endAdornment: adornmentEnd
                }
            }}
        />
    );
};

export default NumberInput;
